import slika from "../assets/jpg/SPASE DAMESKI.JPG";
import cv from "../assets/pdf/resume.pdf";
export const headerData = {
  name: "Spase Dameski MSc.",
  title: "Fullstack Developer & SAP ABAP Expert",
  desciption: [
    "Verbessern heißt ändern; Perfekt zu sein bedeutet, sich oft zu ändern.",
    "- Winston Churchill",
  ],
  image: slika,
  cv: cv,
  button1Title: "Lebenslauf",
  button2Title: "Kontakt",
};
