import niit from "../assets/png/niit.png";
import gcloud from "../assets/png/gcloud.png";
import ntt from "../assets/png/NTT_Data-Logo.wine.png";
export const achievementData = {
  title: "Schulungen",
  bio: [
    "Mein NodeJS-Training hat meine Kenntnisse in der Entwicklung serverseitiger Anwendungen mit der NodeJS-Laufzeitumgebung verbessert.",
    "Das Google Cloud-Training hat mir geholfen, skalierbare, sichere und kostengünstige Cloud-Lösungen für Kunden zu entwerfen und zu implementieren.",
    "Schließlich ermöglichte mir meine SAP-ABAP Schulung, mich auf die Entwicklung und Wartung von Anwendungen auf der SAP-Plattform zu spezialisieren.",
  ],
  achievements: [
    {
      id: 1,
      title: "RESTful Microservices with NodeJS and Express",
      details:
        "Erkunden die Node.js-Umgebung, erstellen, dokumentieren REST-APIs mit Vanilla und ExpressJS und Sichern die APIs mit JWT und Oauth.",
      date: "August, 2019",
      field: "Coursera - NIIT",
      image: niit,
    },
    {
      id: 2,
      title: "G-Cloud Fundamentals: Core Infrastructure",
      details:
        "Google Cloud Fundamentals: Core Infrastructure stellt wichtige Konzepte und Terminologie für die Arbeit mit Google Cloud vor.",
      date: "Oktober, 2020",
      field: "G-Cloud SkillsBoost",
      image: gcloud,
    },
    {
      id: 3,
      title: "SAP ABAP - CDS Views Training",
      details:
        "Das Training konzentrierte sich darauf, Ihre eigenen CDS-Ansichten und analytischen Abfragen zu verstehen, zu erstellen und dem Frontend zur Verfügung zu stellen",
      date: "November, 2020",
      field: "NTT Data",
      image: ntt,
    },
  ],
};

// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
