// import React, { useContext } from 'react';
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// import { ThemeContext } from './contexts/ThemeContext';
import { Main, ProjectPage } from "./pages";
import { BackToTop } from "./components";
import ScrollToTop from "./utils/ScrollToTop";

import "./App.css";
// import { criteria } from "./utils/filter.js";
import Template404 from "./pages/Template404.js";

function App() {
  // const { theme } = useContext(ThemeContext);

  //console.log("%cDEVELOPER PORTFOLIO", `color:${theme.primary}; font-size:50px`);
  //console.log("%chttps://github.com/hhhrrrttt222111/developer-portfolio", `color:${theme.tertiary}; font-size:20px`);
  // console.log = console.warn = console.error = () => {};

  let [filter] = useState(0);
  // ova e demek filter za tajmaut ama ne raboti ko so treba
  /*
  useEffect(() => {
    let filter = async () => {
      let output = await criteria().catch(console.error);
      setFilter(output);
    };
    filter();
  }, []);
*/

  return (
    <div className="app">
      {filter > 0 ? (
        <Template404 />
      ) : (
        <>
          <Router>
            <ScrollToTop />
            <Switch>
              <Route path="/" exact component={Main} />
              {/*<Route path="/blog" exact component={BlogPage} />*/}
              <Route path="/projects" exact component={ProjectPage} />

              <Redirect to="/" />
            </Switch>
          </Router>
          <BackToTop />
        </>
      )}
    </div>
  );
}

export default App;
