// import one from "../assets/svg/projects/one.svg";
// import two from "../assets/svg/projects/two.svg";
// import three from "../assets/svg/projects/three.svg";
// import four from "../assets/svg/projects/four.svg";
// import five from "../assets/svg/projects/five.svg";
// import six from "../assets/svg/projects/six.svg";
// import seven from "../assets/svg/projects/seven.svg";
// import eight from "../assets/svg/projects/eight.svg";
import webScraper from "../assets/svg/projects/web-scraping.svg";
import gpic from "../assets/svg/projects/how-to-discover-min.svg";
import sap from "../assets/svg/projects/sap-abap-development-support.png";

export const projectsData = {
  title: "Projekte",
  data: [
    {
      id: 1,
      projectName: "Web Scraper",
      projectDesc:
        "Ein Beispiel für einen Web Scraper von einer bekannten deutschen Website, der die Ajax-API verwendet.",
      tags: ["Node.js", "CockroachDB", "RegEx"],
      code: "https://github.com/spasedameski/PublicRep/blob/main/webScraperExample.js",
      demo: "",
      image: webScraper,
    },
    {
      id: 2,
      projectName: "G-Cloud Funktion Web Proxy",
      projectDesc:
        "Ein Beispiel für einen Web-Proxy auf Express.js gebaut, der auf einer Google Cloud-Funktion gehostet wird.",
      tags: ["G-Cloud Functions", "Express.js", "Node.js"],
      code: "https://github.com/spasedameski/PublicRep/blob/main/googleFuncExample.js",
      demo: "https://func-a-etsisonxsa-ew.a.run.app/https://www.google.com/search?q=whats+my+ip",
      image: gpic,
    },
    {
      id: 3,
      projectName: "SAP CDS View",
      projectDesc:
        "Ein Beispiel für eine SAP CDS View, die mit OpenSQL in Eclipse geschrieben wurde.",
      tags: ["SAP ABAP", "OpenSQL", "Eclipse"],
      code: "https://github.com/spasedameski/PublicRep/blob/main/sapCdsViewExample.txt",
      demo: "",
      image: sap,
    },
    /*{
    id: 4,
    projectName: "Android Patient Tracker",
    projectDesc:
      "This project involves the development of an Android application for viewing and managing patient data.",
    tags: ["Flutter", "Firebase"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image: four,
  },
  {
    id: 5,
    projectName: "E-Commerce App",
    projectDesc: "A Simple E-commerce application",
    tags: ["React Native", "Firebase"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image: five,
  },
  {
    id: 6,
    projectName: "Uber Lite",
    projectDesc: "Uber clone",
    tags: ["Flutter"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image: six,
  },
  {
    id: 7,
    projectName: "Stock Market App",
    projectDesc: "A simple stock market API app",
    tags: ["React", "Redux", "Bootstrap"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image: seven,
  },
  {
    id: 8,
    projectName: "Car Pooling System",
    projectDesc:
      "The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution",
    tags: ["Flutter", "React"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image: eight,
  },*/
  ],
};

// Do not remove any fields.
// Leave it blank instead as shown below

/*
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
},
*/
