export const experienceData = {
  title: "Erfahrung",
  data: [
    {
      id: 1,
      company: "KAIDO Tech (Selbständig)",
      jobtitle: " Freiberuflicher Fullstack Developer",
      startYear: "2019",
      endYear: "aktuell",
    },
    {
      id: 2,
      company: "verschidenen Firmen",
      jobtitle: "Controller",
      startYear: "2017",
      endYear: "2019",
    },
  ],
};
