export const aboutData = {
  title: "Wer bin ich?",
  descriptionShort:
    "Moin, mein Name ist Spase. Ich bin freiberuflicher Fullstack Entwickler/SAP ABAP Expert, mit Sitz in Hamburg, Deutschland.",
  descriptionLong: [
    "Ich bin ein engagierter und innovativer Profi, der das erste Jahr meiner Karriere im Finanzwesen verbracht hat. Obwohl ich in meiner Karriere erfolgreich war, war ich schon immer von der Welt der Softwareentwicklung fasziniert.",
    "Motiviert durch meine Leidenschaft für Technologie, beschloss ich, einen Vertrauensvorschuss zu wagen und meine Karriere vom Finanzwesen in die Welt der Softwareentwicklung zu verlagern.",
    "Durch Ausdauer, harte Arbeit und Hingabe bin ich in die Softwareentwicklungsbranche eingetreten und habe mich schnell hervorgetan, indem ich meine übertragbaren Fähigkeiten genutzt habe, um zum Erfolg meines Teams beizutragen.",
    "Momentan liegt mein Fokus auf der Entwicklung von NodeJS und SAP ABAP Applikationen.",
  ],
  descENG:
    "I am a driven and innovative professional who has spent the the first couple of years of my career working in finance. Despite achieving success in my career, I have always been intrigued by the world of software development.\n" +
    "Motivated by my passion for technology, I decided to take a leap of faith and transition my career from finance to the software development world. \n" +
    "Despite facing challenges along the way, I remained focused and committed to achieving my goal of becoming a software developer. Through perseverance, hard work, and dedication, I entered the software development industry and quickly excelled, leveraging my transferable skills to contribute to the success of my team.\n" +
    "At the moment my focus is on developing NodeJS and SAP ABAP applications ",
  image: 1,
};
//Trotz der Herausforderungen auf dem Weg blieb ich konzentriert und engagiert, um mein Ziel, Softwareentwickler zu werden, zu erreichen.
