export const educationData = {
  title: "Ausbildung",
  data: [
    {
      id: 1,
      institution: "University of Bristol, Bristol, UK",
      course: "MSc. Finanzwesen",
      startYear: "2016",
      endYear: "2017",
    },
    {
      id: 2,
      institution: "FON University, Skopje, Mazedonien",
      course: "BSc. Finanzmanagement",
      startYear: "2012",
      endYear: "2016",
    },
  ],
};
