export const contactsData = {
  title: "Kontakt",
  email: "s.dameski@kaidotech.de",
  phone: "+49 40 52167737",
  address: "Trettaustraße 17, 21107 Hamburg ",
};

export const formData = {
  nameTitle: "Name",
  name: "Max Mustermann",
  emailTitle: "Email",
  email: "Max@Mustermann.com",
  messageTitle: "Nachricht",
  message: "Ihre Nachricht hier...",
  sendButton: { statusToSend: "Schicken", statusSent: "Geschickt" },
};
