/* eslint-disable */

import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { AiOutlineNodeCollapse, AiOutlineCode } from "react-icons/ai";
import { FaDatabase } from "react-icons/fa";
import { SiSap, SiGooglecloud } from "react-icons/si";

export const servicesData = {
  title: "Dienstleistungen",
  description:
    "These are some of the services I offer. Reach out to me if I can help you with any!",
  data: [
    {
      id: 1,
      title: "Software Development",
      icon: <AiOutlineCode />,
    },
    {
      id: 2,
      title: "Web Development",
      icon: <BsCodeSlash />,
    },
    {
      id: 3,
      title: "Micro Services",
      icon: <AiOutlineNodeCollapse />,
    },
    {
      id: 4,
      title: "Data Management",
      icon: <FaDatabase />,
    },
    {
      id: 5,
      title: "Data Visualisation",
      icon: <BsClipboardData />,
    },
    {
      id: 6,
      title: "G-Cloud Functions",
      icon: <SiGooglecloud />,
    },
    {
      id: 7,
      title: "SAP/CDS Views",
      icon: <SiSap />,
    },
    // {
    //     id: 5,
    //     title: 'Tutoring',
    //     icon: <BsCodeSlash />
    // },

    // {
    //     id: 8,
    //     title: 'Pinterest Virtual Assistant',
    //     icon: <FaPinterest />
    // },
    // {
    //   id: 9,
    //   title: "Video Editing",
    //   icon: <FaVideo />,
    // },
    // {
    //     id: 10,
    //     title: 'Data Entry',
    //     icon: <BsClipboardData />
    // },
    // {
    //   id: 11,
    //   title: "Ebook Writing",
    //   icon: <FaTabletAlt />,
    // },
    // {
    //     id: 12,
    //     title: 'Audio Transcription',
    //     icon: <AiFillAudio />
    // },
    // {
    //   id: 13,
    //   title: "Blog Posting",
    //   icon: <FaRegNewspaper />,
    // },
  ],
};

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.
